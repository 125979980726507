import dynamic from "next/dynamic";

export const storyblokComponentsConfig = {
	post: dynamic(() => import("./components/StoryPost").then((module) => module.StoryPost) as any),
	paragraph: dynamic(() => import("./components/StoryParagraph").then((module) => module.StoryParagraph) as any),
	code: dynamic(() => import("./components/StoryCode").then((module) => module.StoryCode) as any),
	section: dynamic(() => import("./components/StorySection").then((module) => module.StorySection) as any),
	page: dynamic(() => import("./components/StoryPage").then((module) => module.StoryPage) as any),
	post_overview: dynamic(
		() => import("./components/StoryPostOverview").then((module) => module.StoryPostOverview) as any
	),
	grid: dynamic(() => import("./components/StoryGrid").then((module) => module.StoryGrid) as any),
	grid_column: dynamic(() => import("./components/StoryGridColumn").then((module) => module.StoryGridColumn) as any),
	grid_row: dynamic(() => import("./components/StoryGridRow").then((module) => module.StoryGridRow) as any),
};
